.wrapper {
	background: #040200;
	padding: 100px 0 100px;
	@media (max-width: 700px) {
		padding: 50px 0 100px;
	}
}
.title {
	font-size: 74px;
	max-width: 700px;
	font-weight: 400;
	line-height: normal;
	text-transform: uppercase;
	text-align: center;
	margin: 0 auto 50px;
	@media (max-width: 1300px) {
		font-size: 60px;
	}
	@media (max-width: 1100px) {
		font-size: 48px;
		max-width: 600px;
		margin: 0 auto 30px;
	}
	@media (max-width: 700px) {
		font-size: 35px;
		font-weight: 400;
		max-width: 350px;
	}
}
.mainCircle {
	position: relative;
	width: 208px;
	height: 208px;
	z-index: 3;
	background: #2c4135;
	padding-top: 64px;
	border-radius: 50%;
	display: flex;
	// justify-content: center;
	align-items: center;
	text-transform: uppercase;
	flex-direction: column;
	@media (max-width: 800px) {
		width: 150px;
		height: 150px;
		padding-top: 45px;
		& > svg {
			width: 100px;
		}
		& > p {
			font-size: 14px;
		}
	}
	@media (max-width: 600px) {
		width: 78px;
		height: 78px;
		padding-top: 22px;
		& > svg {
			width: 60px;
			margin-top: -15px;
		}
		& > p {
			font-size: 6px;
		}
	}
}
.circlesWrapper {
	max-width: 928px;
	margin: 0 auto;
	height: 876px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	& > div:not(:last-child) {
		width: 530px;
		height: 530px;
		border-radius: 50%;
		border: 1.164px solid rgba(229, 225, 210, 0.4);
		display: flex;
		justify-content: center;
		align-items: center;
		// padding-top: 100px;
		transition: all 0.2s;
		background: transparent;
		z-index: 1;
		text-align: center;
		&:hover {
			z-index: 2;
			transition: all 0.2s;
			border: 1.164px solid rgba(229, 225, 210);
			background: #2c4135;
		}
	}
	& > div > p {
		font-size: 24px;
		font-weight: 700;
		color: #fff;
	}
	& > div:not(:first-child) {
		& > p {
			max-width: 150px;
		}
	}
	& > div:nth-child(1) {
		position: absolute;
		left: 0;
		top: 0;
	}
	& > div:nth-child(2) {
		position: absolute;
		right: 0;
		top: 0;
	}
	& > div:nth-child(3) {
		padding-top: 90px;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	& > div:nth-child(4) {
		padding-top: 90px;
		position: absolute;
		right: 0;
		bottom: 0;
	}
	@media (max-width: 1050px) {
		max-width: 730px;
		height: 720px;
		& > div:not(:last-child) {
			width: 400px;
			height: 400px;
		}
	}
	@media (max-width: 800px) {
		max-width: 550px;
		height: 530px;
		& > div:not(:last-child) {
			width: 300px;
			height: 300px;
		}
	}
	@media (max-width: 600px) {
		max-width: 350px;
		height: 320px;
		& > div:not(:last-child) {
			width: 200px;
			height: 200px;
		}
		& > div {
			& > p {
				font-size: 14px;
			}
		}
		& > div:nth-child(3) {
			padding-top: 35px;
		}
		& > div:nth-child(4) {
			padding-top: 35px;
		}
	}
}
