.wrapper {
	background: #040200;
	padding-top: 74px;
	padding-bottom: 100px;
	@media (max-width: 1000px) {
		padding: 50px 0 50px;
	}
}
.title {
	margin-bottom: 50px;
	text-align: center;
	font-size: 72px;
	text-transform: uppercase;
	font-weight: 400;
	line-height: normal;
	@media (max-width: 1000px) {
		font-size: 52px;
		margin-bottom: 30px;
	}
	@media (max-width: 720px) {
		font-size: 41px;
		font-weight: 400;
	}
	@media (max-width: 450px) {
		font-size: 35px;
		max-width: 300px;
		margin: 0 auto 30px;
	}
}
.blockLine {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
	@media (max-width: 600px) {
		margin-bottom: 30px;
	}
	@media (max-width: 450px) {
		flex-direction: column;
		margin-bottom: 0;
	}
}
.blockItem {
	height: 162px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0 75px;
	position: relative;
	max-width: 500px;
	width: 100%;
	@media (max-width: 1000px) {
		padding: 0 25px;
	}

	&:not(:last-child):after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: 1px;
		height: 162px;
		background: #fff;
	}
	& > div {
		color: #dfa440;
		text-align: center;
		font-size: 64px;
		font-style: normal;
		text-transform: uppercase;
		margin-bottom: 10px;
	}
	& > p {
		font-size: 18px;
		text-align: center;
		max-width: 350px;
		font-family: "Montserrat", sans-serif;
		& > span {
			font-family: "Montserrat", sans-serif;
			font-weight: 700;
		}
	}
	@media (max-width: 600px) {
		height: 112px;
		& > div {
			font-size: 35px;
		}
		& > p {
			font-size: 14px;
			& > span {
				font-size: 14px;
			}
		}
		&:not(:last-child):after {
			height: 112px;
		}
	}
	@media (max-width: 450px) {
		margin-bottom: 30px;
		&:not(:last-child):after {
			display: none;
		}
		height: fit-content;
	}
}
.blockItemSvg {
	display: none;
	@media (max-width: 450px) {
		display: block;
	}
}
