.wrapper {
	background-image: url("../../../../public/src/img/main/other/bg-3.webp");
	height: 716px;
	background-position: left;
	background-repeat: unset;
	background-size: cover;
	background-attachment: local;
	padding-top: 96px;
	@media (max-width: 600px) {
		padding-top: 80px;
		height: 800px;
		background-position: -560px 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-image: url("../../../../public/src/img/main/other/top-small-bg.jpg");
	}
	@media (max-width: 500px) {
		background-position: -600px 50px;
	}
	@media (max-width: 450px) {
		background-position: -650px 50px;
	}
}

.title {
	max-width: 1078px;
	//font-size: 82px;
	font-size: 70px;
	text-transform: uppercase;
	margin-bottom: 25px;
	margin-top: 60px;
	font-weight: 400;
	line-height: normal;
	@media (max-width: 1026px) {
		font-size: 64px;
	}
	// prod
	@media (max-width: 800px) {
		font-size: 56px;
	}
	@media (max-width: 600px) {
		font-size: 35px;
		font-weight: 400;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	@media (max-width: 420px) {
		max-width: 330px;
	}
}
.subtitle {
	font-size: 22px;
	font-weight: 700;
	line-height: 140.4%;
	font-family: "Montserrat", sans-serif;
	& > span {
		font-weight: 400;
	}
	margin-bottom: 46px;
	@media (max-width: 600px) {
		font-size: 14px;
		margin-bottom: 20px;
		max-width: 300px;
	}
	@media (max-width: 420px) {
		max-width: 240px;
	}
}
