.wrapper {
	background: #040200;
	padding-bottom: 100px;
}
.title {
	font-size: 72px;
	text-transform: uppercase;
	margin-bottom: 50px;
	text-align: center;
	font-weight: 400;
	line-height: normal;
	@media (max-width: 1200px) {
		font-size: 56px;
	}
	@media (max-width: 1100px) {
		font-size: 46px;
		max-width: 680px;
		margin: 0 auto 30px;
	}
	@media (max-width: 700px) {
		font-size: 35px;
		font-weight: 400;
	}
}
.servicesWrapper {
	max-width: 1410px;
	margin: 0 auto;
	display: grid;
	grid-gap: 80px 120px;
	grid-template-columns: repeat(2, 1fr);
	padding: 0 65px;
	@media (max-width: 1200px) {
		grid-gap: 50px;
	}
	@media (max-width: 700px) {
		grid-gap: 20px;
	}
	@media (max-width: 500px) {
		padding: 0 10px;
	}
}
.servicesItem {
	display: flex;
	align-items: center;
	& > img {
		width: 240px;
		border-radius: 12px;
	}
	@media (max-width: 1100px) {
		flex-direction: column;
		& > img {
			margin-bottom: 20px;
		}
	}
	@media (max-width: 700px) {
		& > img {
			width: 150px;
			margin: 0 0 10px 0;
		}
		& > div {
			& > p {
				font-size: 14px;
				text-align: center;
				& > span {
					font-size: 14px;
				}
			}
		}
	}
}
.servicesItemContent {
	max-width: 240px;

	& > p {
		font-family: Montserrat;
		font-size: 22px;
		& > span {
			font-weight: 700;
			font-family: Montserrat;
			font-size: 22px;
		}
	}
}
.servicesItemImage {
	margin-right: 32px;
}
